.MainSlide{
    margin-top: 65px;
    width: 80%;
    box-shadow: 1px -1px 20px 0px #3b3a3a69;
}

@media screen and (max-width: 750px) {
    .MainSlide{
        margin-top: 15px;
        width:95%;
        box-shadow: 1px -1px 20px 0px #3b3a3a69;
    }
}

.mainSlideImg{
    width: 100%;
}

.mainSlideHandControler{
    margin-top: -80px;
    height: 80px;
}

.mainSlideHandControlerItem {
    height: 12px;
    width: 12px;
    margin: 2px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    border: none;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);

  }

  .mainSlideHandControlerItem:hover {
    background-color: #DC7633; 
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }

  