.showImgBox{

 background-color: #3c3c3c;
 position: absolute;
 width: 100%;
 height: 95vh;
padding-bottom: 20px;

}

.closeImg{
    text-decoration: none;
    color: white;
    font-size: 35px;
    float: right;
    margin-right: 50px;
}

.imgTagInShowImg{
    margin-top:25px;
    height: 70vh;
}

.mainGalleryBox
{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 20px;
    grid-gap: 20px;

}



.listImgItem{
    display: inline-block;
    height: 250px;
    margin: 5px;
    box-shadow: 1px -1px 20px 0px #3b3a3a69;
}

.listImgItemLink
{
    text-decoration: none;
}

@media screen and (max-width: 750px) {
    .imgTagInShowImg{
        width: 90%;
        height: inherit;
        margin-top: 100px;
    }

    .closeImg{
        margin-top: 30px;
    }
}