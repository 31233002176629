
.headerMenu{

    height: 100px;
    width: 100%;
    background-color: white;
    box-shadow: 1px 2px 20px 0px #DC7633;
}

.headerMenuLink{
    text-decoration: none;
    color: black;
    margin-right:20px;
    font-size: 23px;
}

.headerMenuLink:hover{
    text-decoration: underline;
  }

.menuBox{
    display: inline;
    position: absolute;
    right: 50px; 
    margin-top: 20px; 
}

.headerLogo{
    display: inline;
    height: 90px;
    margin-top: 5px;
    margin-left: 50px;

}

.mobileMenu{

    background-color: #3c3c3c;
    padding: 15px;
    
}

.mobileHeaderMenuLink{
    display: block;
    text-decoration: none;
    text-align: center;
    color: white;
    margin: 15px;
    font-size: 28px;
}

.mobileMenuButton{
  display: block;
}

.mobileMenuButtonContenier{
    display: none;
    background-color: white;
    padding: 10px;
}

@media screen and (max-width: 750px) {
    .mobileMenuButtonContenier{
        display: block;
    }

    .menuBox{
        display: none;
    }

    /* .headerMenu{
        text-align: center;
    } */

    
}